/* eslint-disable complexity */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Card, Container, Navbar, Nav, Ratio, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaAngleDoubleDown } from "react-icons/fa";
import GenericDynamicContent from "../components/GenericDynamicContent";
import { loadUIContext } from "../actions/UIContextActions";
import { newsStateData } from "../actions/NewsActions";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import WebContext from "../utils/WebContext";

class News extends React.Component {
    constructor( props ) {
        super( props );
        this.renderCategory = this.renderCategory.bind( this );
        this.renderCategoryItems = this.renderCategoryItems.bind( this );
        this.page = Pages.getPage( "news" );
        const selectedCategory = props.news.categories ? props.news.categories.find( ( category ) => props.location.pathname.match( new RegExp( `${ this.page.relativeUrl }/${ category.id }` ) ) ) : null;
        this.state = {
            categoryId: selectedCategory && selectedCategory.id ? selectedCategory.id : props.news.defaultCategoryId,
            selectedItem: null
        };
        this.renderArticle = this.renderArticle.bind( this );
        this.getCategory = this.getCategory.bind( this );
        this.getCategoryName = this.getCategoryName.bind( this );
        this.items = props.news.items.filter( ( elem ) => ( elem.active || typeof elem.active === "undefined" ) && ( !elem.datetimePublished || DataUtils.isPastDateTime( elem.datetimePublished ) ) );
        this.state.selectedItem = this.items.find( ( item ) => item.relativeUrl === props.location.pathname );
    }

    componentDidMount() {
        this.props.loadUIContext();
    }

    getCategoryName( categoryId ) {
        let category = this.props.news.categories.filter( ( item ) => categoryId === item.id )[ 0 ];
        category = Pages.applyNewsOverides( category );
        return category.name;
    }
    getCategory( categoryId ) {
        let category = this.props.news.categories.filter( ( item ) => categoryId === item.id )[ 0 ];
        category = Pages.applyNewsOverides( category );
        return category;
    }
    static getCategoryUrl( baseurl, categoryId ) {
        return `${ baseurl }/${ categoryId }`;
    }

    renderCategory() {
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        const category = this.getCategory( this.state.categoryId );
        const itemCoverDimensions = Object.assign( {}, coverDimensions );
        if ( category.aspectRatio ) {
            if ( isLandscape ) {
                itemCoverDimensions.width = 690;
            }
            itemCoverDimensions.height = ( itemCoverDimensions.width / category.aspectRatio.width ) * category.aspectRatio.height;
        }
        return (
            <div className="news_category_container">
                { category.displayHeader ?
                    <div className="news_category_header">
                        <Card className>
                            { !category.vimeoTop && ( category.videoTopPreview || category.imageTop ) ?
                                <Card.Img
                                    variant="top"
                                    src={ category.videoTopPreview ? category.videoTopPreview : category.imageTop }
                                    alt={ this.page.seoDefaultAlt }
                                /> : null }
                            <Card.Body>
                                { category.vimeoTop ?
                                    <Ratio aspectRatio="16x9">
                                        <iframe title={ `vimeo-${ category.id }` } src={ `https://player.vimeo.com/video/${ category.vimeoTop }` } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                    </Ratio>
                                    : null
                                }
                                <Card.Title>
                                    { category.title }
                                </Card.Title>
                                { category.textIsHTML ? <div dangerouslySetInnerHTML={ { __html: category.text } } />
                                    : <Card.Subtitle className="mb-2 text-muted">{ category.text }</Card.Subtitle> }
                            </Card.Body>
                        </Card>
                    </div> : null
                }
                <div className="news_category_items">
                    { News.renderPaginatedCategoryItems( this.renderCategoryItems() ) }
                </div>
            </div>
        );
    }
    static renderPaginatedCategoryItems( items ) {
        const cols = 3;
        const result = [];
        let colsItems = [];
        for ( let i = 0; i < items.length; i += 1 ) {
            const colNumber = i + 1;
            colsItems.push( items[ i ] );
            if ( colNumber % cols === 0 || colNumber === items.length ) {
                // filling left cols at the end
                const modCols = colNumber % cols;
                if ( modCols > 0 ) {
                    for ( let c = 0; c < cols - modCols; c += 1 ) {
                        colsItems.push( [] );
                    }
                }
                result.push( <Row key={ `col-shop-${ i }` }> { colsItems.map( ( tmp, ci ) => <Col xs="12" lg="4" key={ `col-shop-${ i }-${ ci }` }> { tmp }</Col> ) } </Row> );
                colsItems = [];
            }
        }
        return result;
    }

    renderCategoryItems() {
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        return this.items.filter( ( item ) => item.categoryId === this.state.categoryId || this.state.categoryId === "latest" ).map( ( argitem, index ) => {
            if ( this.state.categoryId === "latest" && index >= this.props.news.latestMaxItems ) {
                return null;
            }
            const item = Pages.applyNewsOverides( argitem );
            return (
                <Card key={ `item-${ item.id }` }>
                    { !item.vimeoTop ?
                        <Card.Img
                            onClick={ () => {
                                window.location.href = item.relativeUrl;
                            } }
                            variant="top"
                            src={ item.videoTopPreview ? item.videoTopPreview : item.imageTop }
                            alt={ this.page.seoDefaultAlt }
                        /> : null }
                    <Card.Body>
                        { item.vimeoTop ?
                            <Ratio style={ { width: "100%" } } aspectRatio={ item.aspectRatio ? `${ item.aspectRatio.width }x${ item.aspectRatio.height }` : "16x9" }>
                                <iframe title={ `vimeo-${ item.id }` } src={ `https://player.vimeo.com/video/${ item.vimeoTop }` } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                            </Ratio>
                            : null
                        }
                        <Card.Title>
                            <a href={ `${ item.relativeUrl }` }>
                                { item.title }
                            </a>
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{ this.props.news.displayCreationDate ? `${ item.datetimeCreated } | ` : null }<a href={ News.getCategoryUrl( this.page.relativeUrl, item.categoryId ) }>{ this.getCategoryName( item.categoryId ) }</a></Card.Subtitle>
                        { item.shortText ?
                            <Card.Text>
                                { item.shortText }
                            </Card.Text> : []
                        }
                        { item.longText || item.dynamicContent ? <Card.Link href={ `${ item.relativeUrl }` }>{ Pages.text( this.context.language, "news.viewmore" ) }</Card.Link> : [] }
                    </Card.Body>
                </Card>
            );
        } );
    }

    renderArticleDynamicContent() {
        if ( !this.state.selectedItem || !this.state.selectedItem.dynamicContent ) {
            return null;
        }
        return <GenericDynamicContent items={ this.state.selectedItem.dynamicContent } />;
    }

    renderArticle() {
        let item = this.state.selectedItem;
        if ( !item ) {
            return null;
        }
        item = Pages.applyNewsOverides( item );
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        return (
            <Card className="pw_news_article">
                { item.videoTop ?
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video width="100%" className="pw_news_video" autoPlay loop controls muted playsinline>
                        <source src={ `${ item.videoTop }.mp4` } type="video/mp4" />
                        <source src={ `${ item.videoTop }.ogg` } type="video/ogg" />
                    </video> : null }
                { item.imageTop && !item.hideImageTopOnDetails ?
                    <Card.Img
                        variant="top"
                        src={ item.imageTop }
                        alt={ this.page.seoDefaultAlt }
                    />
                    : null }
                <Card.Body>
                    { item.vimeoTop ?
                        <Ratio style={ { width: "100%" } } aspectRatio={ item.aspectRatio ? `${ item.aspectRatio.width }x${ item.aspectRatio.height }` : "16x9" }>
                            <iframe title={ `vimeo-${ item.id }` } src={ `https://player.vimeo.com/video/${ item.vimeoTop }?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1` } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                        </Ratio>
                        : null
                    }
                    { !item.hideTitleOnDetails ? <Card.Title>{ item.title }</Card.Title> : null }
                    <Card.Subtitle className="mb-2 text-muted">{ this.props.news.displayCreationDate ? `${ item.datetimeCreated } | ` : null }{ !item.hideCategoryLinkOnDetails ? <a href={ News.getCategoryUrl( this.page.relativeUrl, item.categoryId ) }>{ this.getCategoryName( item.categoryId ) }</a> : null }</Card.Subtitle>
                    <div>{ item.longText }</div>
                    {item.longTextImage ?
                        <div className="pw_new_detail_image">
                            <img style={ { width: "100%" } } src={ item.longTextImage } alt={ this.page.seoDefaultAlt } />
                        </div> : []
                    }
                    <div className="pw_news_article_dynamic_content">
                        { this.renderArticleDynamicContent() }
                    </div>
                    <br /><br />
                </Card.Body>
                <Helmet>
                    { item.title ? <title>{ item.seoTitle ? item.seoTitle : item.title }</title> : null }
                    { item.title ? <meta property="og:title" content={ item.title } /> : null }
                    { item.title ? <meta name="DC.title" content={ item.title } /> : null }
                    { item.title ? <meta name="twitter:title" content={ item.title } /> : null }
                    { item.shortText ? <meta name="description" content={ item.shortText } /> : null }
                    { item.shortText ? <meta name="keywords" content={ item.shortText } /> : null }
                    { item.shortText ? <meta property="og:description" content={ item.shortText } /> : null }
                    { item.imageTop ?
                        <meta property="og:image" content={ `${ Pages.company.baseURL }${ item.imageTop }` } />
                        :
                        <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    }
                    <meta property="og:url" content={ `${ Pages.company.baseURL }${ item.relativeUrl }` } />
                    <link rel="canonical" href={ `${ Pages.company.baseURL }${ item.relativeUrl }` } />
                </Helmet>
            </Card>
        );
    }

    render() {
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        let displayCategories = !( this.state.selectedItem && this.state.selectedItem.hideCategories );
        if ( this.props.news.hideCategories ) {
            displayCategories = false;
        }
        const currentCategory = this.getCategory( this.state.categoryId );
        return (
            <div id="news" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                { displayCategories ?
                    <Navbar bg="light" expand="lg" collapseOnSelect>
                        <Navbar.Brand href="#home">{ !isLandscape ? currentCategory.name : "" }</Navbar.Brand>
                        <Navbar.Toggle style={ { border: 0 } } aria-controls="basic-navbar-nav">
                            <FaAngleDoubleDown />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto" variant="pills" activeKey={ currentCategory.id }>
                                { this.props.news.categories.filter( filterCat => filterCat.active || typeof filterCat.active === "undefined" ).map( ( argcategory ) => {
                                    const category = Pages.applyNewsOverides( argcategory );
                                    return ( <Nav.Link href={ `${ this.page.relativeUrl }/${ category.id }` } eventKey={ category.id } key={ `news-category-${ category.id }` }>{ category.name }</Nav.Link> );
                                } )
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    : null
                }
                <Container className="pw_news">
                    { this.renderCategory() }
                    { this.renderArticle() }
                </Container>
            </div>
        );
    }
}
News.serverFetch = newsStateData;
News.serverFetchType = { type: "data", module: "news" };
News.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    news: state.news
} );

const mapDispatchToProps = {
    loadUIContext
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( News ) );
