import fetch from "isomorphic-fetch";
import * as ManagerUtils from "./utils/ManagerUtils";
import * as DataUtils from "./utils/DataUtils";

export function updateCustomerStatus ( newStatus, customerId, customerEmail ) {
    return fetch( "/api/manager/customers/status", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { customerStatus: newStatus, customerId, customerEmail } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function updateCustomer ( Customer ) {
    return fetch( "/api/manager/customers", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( Customer )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function mergeCustomers ( mainCustomer, customerDuplicate ) {
    return fetch( "/api/manager/customers/merge", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { mainCustomer, customerDuplicate } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function newCustomer ( Customer ) {
    return fetch( "/api/manager/customers", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( Customer )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function fetchCarts () {
    return fetch( "/api/manager/carts", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function fetchCustomers ( customerId ) {
    let url = "/api/manager/customers";
    if ( customerId ) {
        url = `/api/manager/customers?customerId=${ customerId }`;
    }
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function postCartStatusUpdate ( newStatus, cartCode, cartEmail ) {
    return fetch( "/api/manager/cart/status", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( {
            status: newStatus,
            cartCode,
            cartEmail
        } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
// invoices
export function fetchInvoices ( filters ) {
    let queryParams = "";
    if ( filters ) {
        const params = new URLSearchParams( filters );
        queryParams = `?${ params.toString() }`;
    }
    let url = `/api/manager/invoices${ queryParams }`;
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function updateInvoiceStatus ( newStatus, invoiceId ) {
    return fetch( "/api/manager/invoices/status", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { invoiceStatus: newStatus, invoiceId } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function updateInvoice ( Invoice ) {
    return fetch( "/api/manager/invoices", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( Invoice )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newInvoice ( Invoice ) {
    return fetch( "/api/manager/invoices", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( Invoice )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function deleteInvoice ( invoiceId ) {
    return fetch( "/api/manager/invoices", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { invoiceId } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function deleteIncome ( incomeId ) {
    return fetch( "/api/manager/incomes", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { incomeId } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function sendInvoice ( Invoice ) {
    return fetch( "/api/manager/invoices/send", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( Invoice )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function creditInvoiceFromInvoice ( invoice ) {
    return fetch( "/api/manager/creditinvoice", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { invoice } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchWebUsers () {
    return fetch( "/api/manager/webusers", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchCourses () {
    return fetch( "/api/manager/courses", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchCoursesCategories () {
    return fetch( "/api/manager/courses/categories", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchServicesCategories () {
    return fetch( "/api/manager/services/categories", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newServiceCategory ( item ) {
    return fetch( "/api/manager/services/categories", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateServiceCategory ( item ) {
    return fetch( "/api/manager/services/categories", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchCourse ( courseId ) {
    return fetch( `/api/manager/courses/${ courseId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newCourse ( cardImageFile, course, fileCoursePDFDossier ) {
    const data = new FormData();
    data.append( "cardImageFile", cardImageFile );
    data.append( "fileCoursePDFDossier", fileCoursePDFDossier );
    data.append( "data", JSON.stringify( course ) );
    return fetch( "/api/manager/courses", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateCourse ( cardImageFile, course, fileCoursePDFDossier ) {
    const data = new FormData();
    data.append( "cardImageFile", cardImageFile );
    data.append( "fileCoursePDFDossier", fileCoursePDFDossier );
    data.append( "data", JSON.stringify( course ) );
    return fetch( "/api/manager/courses", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newCourseCategory ( category ) {
    return fetch( "/api/manager/courses/categories", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( category )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateCourseCategory ( category ) {
    return fetch( "/api/manager/courses/categories", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( category )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function uploadCourseFile ( file, course ) {
    const data = new FormData();
    data.append( "file", file );
    data.append( "data", JSON.stringify( course ) );
    return fetch( "/api/manager/courses/content", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

// course steps
export function newCourseStep ( courseId, step ) {
    return fetch( `/api/manager/courses/${ courseId }/steps`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( step )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateCourseStep ( courseId, step ) {
    return fetch( `/api/manager/courses/${ courseId }/steps`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( step )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// course classes
export function newCourseClass ( courseId, item ) {
    return fetch( `/api/manager/courses/${ courseId }/classes`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateCourseClass ( courseId, item ) {
    return fetch( `/api/manager/courses/${ courseId }/classes`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newCourseStepContent ( fileCourseStepContent, content ) {
    const data = new FormData();
    data.append( "fileCourseStepContent", fileCourseStepContent );
    data.append( "data", JSON.stringify( content ) );
    return fetch( `/api/manager/courses/${ content.courseStepContentCourseId }/steps/${ content.courseStepContentStepId }/content`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateCourseStepContent ( fileCourseStepContent, content ) {
    const data = new FormData();
    data.append( "fileCourseStepContent", fileCourseStepContent );
    data.append( "data", JSON.stringify( content ) );
    return fetch( `/api/manager/courses/${ content.courseStepContentCourseId }/steps/${ content.courseStepContentStepId }/content`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchCourseClass ( courseClassId ) {
    return fetch( `/api/manager/courses/classes/${ courseClassId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updateCourseClassUser ( item ) {
    return fetch( `/api/manager/courses/classes/users/${ item.courseClassUserId }`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newCourseClassUser ( item ) {
    return fetch( "/api/manager/courses/classes/users", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function fetchTeam () {
    return fetch( "/api/manager/team/members", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newTeamMember ( teamMemberImage, teamMember ) {
    const data = new FormData();
    data.append( "teamMemberImage", teamMemberImage );
    data.append( "data", JSON.stringify( teamMember ) );
    return fetch( "/api/manager/team/members", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateTeamMember ( teamMemberImage, teamMember ) {
    const data = new FormData();
    data.append( "teamMemberImage", teamMemberImage );
    data.append( "data", JSON.stringify( teamMember ) );
    return fetch( "/api/manager/team/members", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newTeamFeeSchema ( item ) {
    return fetch( "/api/manager/team/feeschemas", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateTeamFeeSchema ( item ) {
    return fetch( "/api/manager/team/feeschemas", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchTeamFeeSchemas () {
    return fetch( "/api/manager/team/feeschemas", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newTeamFeeSchemaTarget ( item ) {
    return fetch( "/api/manager/team/feeschemas/target", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateTeamFeeSchemaTarget ( item ) {
    return fetch( "/api/manager/team/feeschemas/target", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newService ( serviceImageWeb, serviceIcon, item ) {
    const data = new FormData();
    data.append( "serviceImageWeb", serviceImageWeb );
    data.append( "serviceIcon", serviceIcon );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/services", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateService ( serviceImageWeb, serviceIcon, item ) {
    const data = new FormData();
    data.append( "serviceImageWeb", serviceImageWeb );
    data.append( "serviceIcon", serviceIcon );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/services", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchServices () {
    return fetch( "/api/manager/services", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchResources () {
    return fetch( "/api/manager/resources", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newResource ( item ) {
    return fetch( "/api/manager/resources", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateResource ( item ) {
    return fetch( "/api/manager/resources", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchBonos () {
    return fetch( "/api/manager/bonos", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newBono ( item ) {
    return fetch( "/api/manager/bonos", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateBono ( item ) {
    return fetch( "/api/manager/bonos", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newBonoService ( item ) {
    return fetch( "/api/manager/bonos/services", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateBonoService ( item ) {
    return fetch( "/api/manager/bonos/services", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// sales
export function fetchSales ( filters ) {
    let queryParams = "";
    if ( filters ) {
        const params = new URLSearchParams( filters );
        queryParams = `?${ params.toString() }`;
    }
    let url = `/api/manager/sales${ queryParams }`;
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newSale ( sale ) {
    return fetch( "/api/manager/sales", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( sale )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateSale ( item ) {
    return fetch( "/api/manager/sales", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function paySalesDebt ( payments ) {
    return fetch( "/api/manager/sales/paydebt", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { payments } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// sales
export function fetchPages () {
    return fetch( "/api/manager/pages", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
const preparePage = ( page ) => {
    const data = Object.assign( {}, page );
    const fileImageUrlResult = [];
    data.dynamicContent.map( tmp => {
        switch ( tmp.dynamicContentType ) {
            case "slider":
                tmp.dynamicContentValue.slides.forEach( tmpSlide => {
                    if ( tmpSlide.fileImageUrl ) {
                        // eslint-disable-next-line no-param-reassign
                        tmpSlide.fileImageUrlIndex = fileImageUrlResult.length;
                        fileImageUrlResult.push( tmpSlide.fileImageUrl );
                    }
                } );
                break;
            case "gallery":
            case "cards":
                tmp.dynamicContentValue.forEach( tmpItem => {
                    if ( tmpItem.fileImageUrl ) {
                        // eslint-disable-next-line no-param-reassign
                        tmpItem.fileImageUrlIndex = fileImageUrlResult.length;
                        fileImageUrlResult.push( tmpItem.fileImageUrl );
                    }
                } );
                break;
            case "leftimage":
                if ( tmp.dynamicContentValue.fileImageUrl ) {
                    fileImageUrlResult.push( tmp.dynamicContentValue.fileImageUrl );
                }
                break;
            default:
                break;
        }
        return tmp;
    } );
    return { fileImageUrl: fileImageUrlResult, data };
};
export function newPage ( editing ) {
    const preparedPage = preparePage( editing );
    const formData = new FormData();
    preparedPage.fileImageUrl.forEach( file => {
        formData.append( "fileImageUrl", file );
    } );
    formData.append( "data", JSON.stringify( preparedPage.data ) );
    return fetch( "/api/manager/pages", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: formData
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updatePage ( editing ) {
    const preparedPage = preparePage( editing );
    const formData = new FormData();
    preparedPage.fileImageUrl.forEach( file => {
        formData.append( "fileImageUrl", file );
    } );
    formData.append( "data", JSON.stringify( preparedPage.data ) );
    return fetch( "/api/manager/pages", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: formData
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// shop items
export function fetchShopItemStock ( shopItemId ) {
    return fetch( `/api/manager/shop/items/${ shopItemId }/stock`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchShopItems () {
    return fetch( "/api/manager/shop/items", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchShopItemsCategories () {
    return fetch( "/api/manager/shop/categories", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newShopItem ( shopItemImage, shopItem ) {
    const data = new FormData();
    data.append( "shopItemImage", shopItemImage );
    data.append( "data", JSON.stringify( shopItem ) );
    return fetch( "/api/manager/shop/items", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateShopItem ( shopItemImage, shopItem ) {
    const data = new FormData();
    data.append( "shopItemImage", shopItemImage );
    data.append( "data", JSON.stringify( shopItem ) );
    return fetch( "/api/manager/shop/items", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newShopItemCategory ( item ) {
    return fetch( "/api/manager/shop/categories", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateShopItemCategory ( item ) {
    return fetch( "/api/manager/shop/categories", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function fetchReportsDailyCash ( month ) {
    let monthValue = "";
    if ( month ) {
        monthValue = DataUtils.formatDateToDisplay( month );
    }
    return fetch( `/api/manager/reports/dailycash?month=${ monthValue }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}

export function fetchReportsDailyTeamFee ( month ) {
    let monthValue = "";
    if ( month ) {
        monthValue = DataUtils.formatDateToDisplay( month );
    }
    return fetch( `/api/manager/reports/dailyteamfee?month=${ monthValue }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
// calendar
export function fetchCalendarBookings () {
    return fetch( "/api/manager/calendar/bookings", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchCalendarBookedSlots () {
    return fetch( "/api/manager/calendar/bookedslots", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newBooking ( item ) {
    return fetch( "/api/manager/calendar/bookings", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateBooking ( item ) {
    return fetch( "/api/manager/calendar/bookings", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateBookings ( bookings ) {
    return fetch( "/api/manager/calendar/bookings/batch", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( bookings )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function deleteBooking ( item ) {
    return fetch( "/api/manager/calendar/bookings", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchCustomerPendingBookings ( saleCustomerId ) {
    if ( !saleCustomerId ) {
        return null;
    }
    return fetch( `/api/manager/customers/pendingbookings?saleCustomerId=${ saleCustomerId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchCustomerSales( saleCustomerId ) {
    return fetch( `/api/manager/sales?saleCustomerId=${ saleCustomerId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function fetchCustomerConsents( customerId ) {
    return fetch( `/api/manager/consents?customerId=${ customerId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function sendBooking48hsReminder ( item ) {
    return fetch( "/api/manager/calendar/bookings/send48hsreminder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function scheduleNotification ( transaction ) {
    return fetch( "/api/manager/calendar/schedulenotification", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( transaction )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// locations
export function fetchLocations () {
    return fetch( "/api/manager/locations", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newLocation ( locationImage, location ) {
    const data = new FormData();
    data.append( "locationImage", locationImage );
    data.append( "data", JSON.stringify( location ) );
    return fetch( "/api/manager/locations", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateLocation ( locationImage, location ) {
    const data = new FormData();
    data.append( "locationImage", locationImage );
    data.append( "data", JSON.stringify( location ) );
    return fetch( "/api/manager/locations", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function fetchConsents () {
    return fetch( "/api/manager/consents", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newConsent ( consentFileToSign, item ) {
    const data = new FormData();
    data.append( "consentFileToSign", consentFileToSign );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/consents", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateConsent ( consentFileToSign, item ) {
    const data = new FormData();
    data.append( "consentFileToSign", consentFileToSign );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/consents", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function addConsentCustomer ( customerId, consent ) {
    return fetch( `/api/manager/consents/addcustomer/${ customerId }/${ consent.consentId }`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( {
            consentTitle: consent.consentTitle
        } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateConsentCustomer ( customerConsentSignedFile, customerConsent ) {
    const data = new FormData();
    data.append( "customerConsentSignedFile", customerConsentSignedFile );
    data.append( "data", JSON.stringify( customerConsent ) );
    return fetch( "/api/manager/consents/updatecustomer", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function addServiceShopItem ( item ) {
    return fetch( "/api/manager/services/shopitems", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateServiceShopItem ( item ) {
    return fetch( "/api/manager/services/shopitems", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function deleteServiceShopItem ( item ) {
    return fetch( "/api/manager/services/shopitems", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function newShopItemStock ( item ) {
    return fetch( "/api/manager/shop/items/stock", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// suppliers
export function fetchShopSuppliers () {
    return fetch( "/api/manager/shop/suppliers", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newShopSupplier ( item ) {
    return fetch( "/api/manager/shop/suppliers", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateShopSupplier ( item ) {
    return fetch( "/api/manager/shop/suppliers", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// brands
export function fetchShopBrands () {
    return fetch( "/api/manager/shop/brands", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newShopBrand ( item ) {
    return fetch( "/api/manager/shop/brands", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateShopBrand ( item ) {
    return fetch( "/api/manager/shop/brands", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// pets
export async function fetchCustomerPets ( customerId ) {
    let url = `/api/manager/customers/pets?customerId=${ customerId }`;
    if ( !customerId ) {
        url = "/api/manager/customers/pets";
    }
    const res = await fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res.json();
}
export async function newCustomerPet ( item ) {
    const res = await fetch( "/api/manager/customers/pets", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res;
}
export async function updateCustomerPet ( item ) {
    const res = await fetch( "/api/manager/customers/pets", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res;
}
// linked shop items
export function addServiceLinkedShopItem ( item ) {
    return fetch( "/api/manager/services/linkedshopitems", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateServiceLinkedShopItem ( item ) {
    return fetch( "/api/manager/services/linkedshopitems", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function deleteServiceLinkedShopItem ( item ) {
    return fetch( "/api/manager/services/linkedshopitems", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// linked services
export function addServiceLinkedService ( item ) {
    return fetch( "/api/manager/services/linkedservices", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateServiceLinkedService ( item ) {
    return fetch( "/api/manager/services/linkedservices", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function deleteServiceLinkedService ( item ) {
    return fetch( "/api/manager/services/linkedservices", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// payment links
export async function fetchPaymentLinks ( customerId ) {
    let url = `/api/manager/paymentlinks?customerId=${ customerId }`;
    if ( !customerId ) {
        url = "/api/manager/paymentlinks";
    }
    const res = await fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res.json();
}
export async function newPaymentLink ( item ) {
    const res = await fetch( "/api/manager/paymentlinks", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res;
}
export async function updatePaymentLink ( item ) {
    const res = await fetch( "/api/manager/paymentlinks", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } );
    if ( !res.ok && res.status === 401 ) {
        ManagerUtils.setToken( "" );
    }
    return res;
}
export function updateSaleStatus ( saleId, saleStatus ) {
    return fetch( "/api/manager/sales/status", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { saleId, saleStatus } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// service resources
export function addServiceResource ( item ) {
    return fetch( "/api/manager/services/resources", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateServiceResource ( item ) {
    return fetch( "/api/manager/services/resources", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function deleteServiceResource ( item ) {
    return fetch( "/api/manager/services/resources", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// reminders
export function fetchReminders () {
    return fetch( "/api/manager/reminders", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function newReminder ( item ) {
    return fetch( "/api/manager/reminders", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
export function updateReminder ( item ) {
    return fetch( "/api/manager/reminders", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// Outgoings
export function fetchOutgoings() {
    let url = "/api/manager/outgoings";
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updateOutgoing ( item, outgoingInvoiceFile ) {
    const data = new FormData();
    data.append( "outgoingInvoiceFile", outgoingInvoiceFile );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/outgoings", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newOutgoing ( item, outgoingInvoiceFile ) {
    const data = new FormData();
    data.append( "outgoingInvoiceFile", outgoingInvoiceFile );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/outgoings", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// Incomes
export function fetchIncomes() {
    let url = "/api/manager/incomes";
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updateIncome ( item ) {
    return fetch( "/api/manager/incomes", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newIncome ( item ) {
    return fetch( "/api/manager/incomes", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// manager templates
export function fetchMessagesTemplates() {
    let url = "/api/manager/messagestemplates";
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updateMessageTemplate ( item ) {
    return fetch( "/api/manager/messagestemplates", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newMessageTemplate ( item ) {
    return fetch( "/api/manager/messagestemplates", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// subscription
export function fetchSubscriptions() {
    let url = "/api/manager/subscriptions";
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updateSubscription ( item ) {
    return fetch( "/api/manager/subscriptions", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newSubscription ( item ) {
    return fetch( "/api/manager/subscriptions", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
// promo codes
export function fetchPromoCodes() {
    let url = "/api/manager/promocodes";
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        }
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res.json();
    } );
}
export function updatePromoCode ( item, promoCodeImage ) {
    const data = new FormData();
    data.append( "promoCodeImage", promoCodeImage );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/promocodes", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function newPromoCode ( item, promoCodeImage ) {
    const data = new FormData();
    data.append( "promoCodeImage", promoCodeImage );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/manager/promocodes", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: data
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function downloadBookingsReport ( title, filters ) {
    return fetch( "/api/manager/reports/bookings/download", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( { title, filters } )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}

export function deletePage ( item ) {
    return fetch( "/api/manager/pages", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ ManagerUtils.getToken() }`
        },
        body: JSON.stringify( item )
    } ).then( ( res ) => {
        if ( !res.ok && res.status === 401 ) {
            ManagerUtils.setToken( "" );
        }
        return res;
    } );
}
