/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, FormControl, Form, InputGroup } from "react-bootstrap";
import { FaTruck } from "react-icons/fa";
import Styles from "./styles/Global";
import { contentStateData } from "../../../actions/ContentActions";
import { shopSearch, changeSessionAttr } from "../../../actions/SessionActions";
import * as Pages from "../../../utils/Pages";
import WebContext from "../../../utils/WebContext";
import GenericDynamicContent from "../../../components/GenericDynamicContent";
import { apiFetchDynamicContent } from "../../../api";

class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { slide: null };
        this.data = {
            cards: [
                {
                    type: "card",
                    title: "Equis Store (X Sex & Store)",
                    subTitle: "Palma de Mallorca",
                    subTitleIcon: "location",
                    thirdTitle: "Passatge de Maneu, 10",
                    socialLinksInstagramUrl: null,
                    socialLinksFacebookUrl: null,
                    socialLinksWebUrl: null,
                    className: "pw_courses_carddeck",
                    embed16x9: "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.457023255549!2d2.6538102151032437!3d39.57184911451141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x129792525848334b%3A0xe0fec8dcb28c19fd!2sX%20Sex%26Store!5e0!3m2!1ses!2ses!4v1622710851893!5m2!1ses!2ses\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\"></iframe>"
                },
                {
                    type: "card",
                    title: "Equis Store (La Rambla)",
                    subTitle: "Palma de Mallorca",
                    subTitleIcon: "location",
                    thirdTitle: "La Rambla, 5",
                    socialLinksInstagramUrl: null,
                    socialLinksFacebookUrl: null,
                    socialLinksWebUrl: null,
                    className: "pw_courses_carddeck",
                    embed16x9: "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.4228073787685!2d2.647955315103245!3d39.57262041446519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12979250f564d80f%3A0xda9ca385bd561085!2sLa%20Rambla%2C%205%2C%2007003%20Palma%2C%20Illes%20Balears!5e0!3m2!1ses!2ses!4v1622711090992!5m2!1ses!2ses\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\"></iframe>"
                }
            ]
        };
    }
    componentDidMount() {
        this.props.loadUIContext();
        apiFetchDynamicContent( "Page", "home" )
            .then( result => {
                this.setState( { slide: result.dynamicContent.find( tmp => tmp.dynamicContentType === "slider" ) } );
            } );
    }
    prevSlide() {
        const prevSlide = this.state.counter - 1 < 0 ? this.slides.length - 1 : this.state.counter - 1;
        this.setState( {
            counter: prevSlide
        } );
    }
    nextSlide() {
        const nextSlide = this.state.counter + 1 < this.slides.length ? this.state.counter + 1 : 0;
        this.setState( {
            counter: nextSlide
        } );
    }
    render() {
        const page = Pages.getPage( "home" );
        const lang = this.context.language;
        const homeSlides = [];
        if ( this.state.slide ) {
            homeSlides.push( this.state.slide );
        }
        return (
            <div className={ Pages.getPageClassNames( page ) }>
                <Helmet>
                    <title>{ page.seoTitle }</title>
                    <meta name="description" content={ page.seoDescription } />
                    <meta name="keywords" content={ page.seoKeywords } />
                    <meta name="author" content={ page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ page.seoTitle } />
                    <meta name="DC.description" content={ page.seoDescription } />
                    <meta property="og:title" content={ page.seoTitle } />
                    <meta property="og:description" content={ page.seoDescription } />
                    <meta property="og:url" content={ page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ page.seoDescription } />
                    <meta name="twitter:title" content={ page.seoTitle } />
                </Helmet>
                <GenericDynamicContent items={ homeSlides } />
                <div className="dyncontent-colorsection">
                    <Form
                        onSubmit={ ( e ) => {
                            e.preventDefault();
                            this.props.shopSearch();
                        } }
                        inline
                        className="pw_shop_search_form_home"
                    >
                        <InputGroup>
                            <FormControl type="text" size="md" placeholder={ Pages.text( this.context.language, "navbar.search" ) } onChange={ ( e ) => this.props.changeSessionAttr( "searchText", e.target.value ) } />
                            <Button variant="pw-primary" size="md" onClick={ this.props.shopSearch }>{ Pages.text( this.context.language, "navbar.search" ) }</Button>
                        </InputGroup>
                    </Form>
                    <br />
                    <h5><FaTruck /> { Pages.text( this.context.language, "custom.home.freeshipping" ) }</h5>
                </div>
                <div style={ Styles.Home.SectionContentContainer }>
                    { typeof window !== "undefined" ? <GenericDynamicContent items={ [ { type: "shop_featured_items", deckSize: 4 } ] } /> : null }
                </div>
                <div id="locations" className="pw_section_container" style={ { clear: "both" } }>
                    <h1 className="pw_section_title">Nuestras tiendas físicas</h1>
                    <h2 className="pw_section_subtitle">También te invitamos a visitarnos en nuestras tiendas físicas de Palma de Mallorca</h2>
                    { typeof window !== "undefined" ? <GenericDynamicContent items={ [ { type: "location_cards", value: this.data.cards, deckSize: 2 } ] } /> : null }
                </div>
            </div>
        );
    }
}

Home.contextType = WebContext;
Home.serverFetch = contentStateData;
Home.serverFetchType = {
    type: "data",
    module: "content"
};
const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, { shopSearch, changeSessionAttr } );
export default connect( mapStateToProps, mapDispatchToProps )( Home );
