export default {
    instagram: {
        title: null,
        subTitle: null,
        custom: true,
        instagramURL: "https://www.instagram.com/palmawebs",
        displayInstagramIcon: true,
        socialHeader: {
            instagramTag: "@palmawebs"
        },
        photos: [
            {
                type: "instagram",
                src: "https://www.instagram.com/p/CCGzGOFiCuH/embed",
                width: 1,
                height: 1
            },
            {
                type: "instagram",
                src: "https://www.instagram.com/p/CB8mz6FiL7H/embed",
                width: 1,
                height: 1
            },
            {
                type: "instagram",
                src: "https://www.instagram.com/p/CB8lEz2CXuT/embed",
                width: 1,
                height: 1
            },
            {
                type: "instagram",
                src: "https://www.instagram.com/p/CB8WgPbiWSt/embed",
                width: 1,
                height: 1
            },
            {
                type: "instagram",
                src: "https://www.instagram.com/p/CCMHA-GiaDC/embed",
                width: 1,
                height: 1
            }
        ]
    }
};
